import React from 'react';
import SEO from '../components/organisms/seo';

const NotFoundPage = () => (
  <section className="spacing-inner" style={{ minHeight: '100vh' }}>
    <SEO siteTitle="404" />
    <p>Oh no! This page doesn't exist.</p>
  </section>
);

export default NotFoundPage;
